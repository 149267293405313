<footer class="px-[25px] bg-white dark:bg-[#1b1d2a]">
    <div class="flex justify-between flex-wrap py-[22px] gap-x-[30px] gap-y-[15px] items-center max-md:flex-col">
        <div class="flex items-center gap-[4px] text-[14px] font-medium max-md:text-center text-theme-gray dark:text-white/60">© {{ currentYear }} <a class="text-primary" href="#">SovWare</a></div>
        <div class="justify-end md:justify-center items-center flex gap-[15px]">
            <a href="#" class="text-theme-gray dark:text-white/60 text-[14px] hover:text-primary dark:hover:text-white/[.87]">About</a>
            <a href="#" class="text-theme-gray dark:text-white/60 text-[14px] hover:text-primary dark:hover:text-white/[.87]">Team</a>
            <a href="#" class="text-theme-gray dark:text-white/60 text-[14px] hover:text-primary dark:hover:text-white/[.87]">Contact</a>
        </div>
    </div>
</footer>
